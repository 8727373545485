
import { Component, Prop, Vue } from 'vue-property-decorator';
import Tile from "@/components/tileContent/Tile.vue";
import { IAgencyDB, LabelValue, MissionPositionFront, StoreAction } from "@/types";
import { MissionStatus, ROUTES, WorkerMissionStatus } from "@/data";
import { Action, Getter, State } from "vuex-class";
import moment from "moment-timezone";
import {capitalize, capitalizeAllString, getFormattedHour, pluralize, sortWorkers} from "@/helpers/commons";
import MissionAvailableCommands from "@/components/Mission/MissionAvailableCommands.vue";
import WorkerAvailableCommands from "@/components/Mission/WorkerAvailableCommands.vue";
import Badge from '@/dsComponents/Badge/Badge.vue';

type Position = {
    name: string
    workers: Array<{
        fullname: string
        status: WorkerMissionStatus
    }>
}

@Component({
    name: "MissionTile",
    methods: {capitalizeAllString},
    components: {WorkerAvailableCommands, MissionAvailableCommands, Tile, Badge}
})
export default class MissionTile extends Vue {
    @Prop() readonly mission!: any;
    @Prop() readonly mode!: string;
    @Prop(Boolean) readonly noBottom!: boolean;
    @Prop(Boolean) readonly noCommands!: boolean;
    @Prop(Boolean) readonly noClick!: boolean;
    @Prop(Boolean) readonly noOpen!: boolean;
    @Prop(Boolean) readonly status!: boolean;
    @Getter('jobOptions') jobOptions!: LabelValue[];
    @Getter('isAgency') isAgency!: boolean;
    @Getter('isCompany') isCompany!: boolean;
    @Action('actions/cancelMission') cancelMission!:StoreAction;
    @Getter('userContext') userContext!: string;
    @State('currentAgency') currentAgency!: IAgencyDB;

open = false;

    formatHour = getFormattedHour;
    capitalize = capitalize;

    icons:any = {
        placed: "placed",
        presented: "presented",
        validated: "validated",
        proposed: "proposed",
        accepted: "accepted",
        declined: "declined",
        confirmed: "confirmed",
        notconfirmed: "notconfirmed",
        rejected: "rejected",
        rejectedByAgency: "rejectedByAgency",
        rejectedByCompany: "rejectedByCompany",
        aborted: "aborted",
        canceled: "canceled",
        lost:"lost"
    };

    get missionName() {
        if (this.isAgency) {
            if (!this.mission.name) {
                if (this.mission?.client?.name) {
                    return this.mission.client.name
                }
            }
            return this.mission.name;
        }
        if (this.isCompany) {
            if (!this.mission.agencyId) {
                return this.$t('layout.left.newOrder');
            }
            return this.mission.agency?.name || this.$t('layout.left.newOrder');
        }
        return this.$t('layout.left.newOrder');
    }

    get isMonth() {
        return this.mode === "month";
    }

    get hasPeriods() {
        return this.mission.periods?.length;
    }

    get hasPositions() {
        return this.mission.positions?.length;
    }

    get alerts() {
        return this.mission.alerts || [];
    }

    get isPlanning() {
        return this.$route.name === ROUTES.APP.PLANNING
                || this.$route.name === ROUTES.APP.PLANNING_ORDER
                || this.$route.name === ROUTES.APP.PLANNING_WORKERS
                || this.$route.name === ROUTES.APP.PLANNING_COMPANIES;
    }

    get missionPeriods() {
        if (this.mission.periodMin && this.mission.periodMax) {
            return this.formatPeriod({start: this.mission.periodMin, end: this.mission.periodMax});
        }
        return this.mission?.periods?.map((p: any) => this.formatPeriod(p)).join(', ') || "";
    }

    get positions() : Array<Position> {
        return this.mission.positions?.map((position: any, index: number) => {
            return {
                name: this.getJob(position.jobId)?.label!,
                workers: (this.mission.workers?.map((worker: any) => {
                    console.log(worker);
                    return worker.positionIndex === index && {
                        firstname: worker.firstname,
                        lastname: worker.lastname,
                        status: worker.status,
                        registered: worker.registered,
                        availableCommands: worker.availableCommands,
                        presentationText: worker.presentationText && worker.presentationText[this.currentAgency._id],
                        workerId: worker.workerId || worker._id
                    }
                }) || []).filter((a:any) => a)
            };
        }) || [];
    }

    get isGanttWorkers() {
        return this.isAgency && this.mode === "gantt";
    }

    get isCompanyGanttWorkers() {
        return this.isCompany && this.mode === "gantt";
    }

    get isGanttClient() {
        return this.isAgency && this.mode === "client";
    }

    get createdAt() {
        return moment(this.mission.createdAt).format(this.$t('date.shortFormat'))
    }

    get clientShortName() {
        return capitalize(this.mission.client?.name) || '';
    }

    get agencyShortName() {
        return capitalize(this.mission.agency?.name) || '';
    }

    get ownerAgencyShortName() {
        // Backend can returning empty object
        return Object.keys(this.mission.ownerAgency || {}).length > 0
            ? `${capitalize(this.mission.ownerAgency.firstname)} ${this.mission.ownerAgency?.lastname.charAt(0).toUpperCase() || ''}`
            : '';
    }

    get ownerClientShortName() {
        // Backend can returning empty object
        return Object.keys(this.mission.ownerCompany || {}).length > 0
            ? `${capitalize(this.mission.ownerCompany.firstname)} ${this.mission.ownerCompany.lastname.charAt(0).toUpperCase() || ''}`
            : '';
    }

    get ownerShort() {
        //affichage coté agence
        if (this.isAgency) {
            return this.ownerAgencyShortName ? this.ownerAgencyShortName : this.clientShortName;
        }

        //affichage coté Entreprise
        return this.ownerClientShortName ? this.ownerClientShortName : this.agencyShortName;
    }

    get showAlerts() {
        return this.isPlanning
            || this.$route.name === ROUTES.APP.FOLLOWING
            || this.$route.name === ROUTES.APP.FOLLOWING_ARCHIVE
            || this.$route.name ===ROUTES.APP.FOLLOWING_GLOBAL;
    }

    get hasSendToEvolia() {
        return this.mission.sendToEvoliaTimestamp && this.mission.orderRef && this.mission.orderId;
    }

    get labelApplication() {
        if (this.mission.jobOffer.workerToTreated > 0) return this.$t('mission.jobOffer.applicationToTreat',
        {
            number: this.mission.jobOffer.workerToTreated,
            application: pluralize(<string>this.$t('mission.jobOffer.application'), this.mission.jobOffer.workerToTreated) }).toString();
        return this.$t('mission.jobOffer.noApplicationToTreat');
    }

    activeWorkers(position: Position) {
        return (position?.workers || []).sort(sortWorkers);
    }

    formatPeriod(period: any) {
        if (period.start === period.end) {
            return moment(period.start).format('ddd DD/MM/YY');
        }
        return `${this.$t('global.from')} ${moment(period.start).format('ddd DD/MM/YY')}<br>${this.$t('global.to')} ${moment(period.end).format('ddd DD/MM/YY')}`;
    }

    getJob(id: string) {
        return this.jobOptions.find((i: LabelValue) => i.value === id);
    }

    getJobLabel(id: string): string|null {
        const job = this.getJob(id);
        return job? job.label:null;
    }

    emitCommand(result:any) {
        this.$emit('command', result);
    }

    goToMission() {
        if (!this.noClick) {
            if (this.mission.status === MissionStatus.draft || this.mission.status === MissionStatus.created) {
                this.$router.push({name: ROUTES.APP.MISSIONCREATION, query: {missionId: this.mission._id}});
            } else {
                this.$router.push({name: ROUTES.APP.MISSION._ROOT, params: {missionId: this.mission._id}});
            }
        }
    }

    toggle(event:any) {
        event?.stopPropagation();
        this.open = !this.open;
        this.$emit("onToggle");
    }

    getPositionNumbers(position: MissionPositionFront, index:number) {
        let total = position.quantity;
        let nbWorkers = 0;

        this.mission.workers?.forEach((w:any) => {
            if (w.positionIndex === index && w.status === WorkerMissionStatus.confirmed) {
                ++nbWorkers;
            }
        });

        return `<div class="nb-workers"><span>${nbWorkers}/${total}</span></div>`;
    }

    /*
        Get the job status:
        params:
            - index: index of the job in the mission
            - quantity: amount of interims to provide for the job

        return: (on html format) Number of validated interims by number of interims to provides for the job
     */
    getJobStatus(index:number, quantity: number) {
        let nbWorkers = 0;

        this.mission.workers?.forEach((w:any) => {
            if (w.positionIndex === index) {
                ++nbWorkers;
            }
        });

        return `<div class="nb-workers"><span>${nbWorkers}/${quantity}</span></div>`;
    }

    alertLabel(alert:any) {
        if (alert.kind === "missingWorker") {
            if (alert.data.count === 0) {
                return this.$t('mission.alerts.missingWorker.zero', {total: alert.data?.total || 0});
            } else {
                return this.$t('mission.alerts.missingWorker.more', {nb: alert.data?.count || 0, total: alert.data?.total || 0});
            }
        }
        if (alert.kind === "missionNotCompleted") {
            return this.$t('mission.alerts.missionNotCompleted');
        }
        if (alert.kind === "missingEvaluation") {
            if (alert.data?.who && alert.data?.who !== "both") {
                return this.$t('mission.alerts.missingEvaluation.' + alert.data?.who);
            } else {
                return null;
            }
        }
        if (alert.kind === "replaceWorkers") {
            return this.$t('mission.alerts.replaceWorkers', {total: alert.data.count, plural: (alert.data.count > 1 && 's') || ''});
        }
        if (alert.kind === "workersToConfirmed") {
            return this.$t('mission.alerts.workersToConfirmed', {total: alert.data.toConfirmed, plural: (alert.data.toConfirmed > 1 && 's') || ''});
        }
        if (alert.kind === "workersToValidate") {
            return this.$t('mission.alerts.workersToValidate', {total: alert.data.toValidate, plural: (alert.data.toValidate > 1 && 's') || ''});
        }
        if (alert.kind === "workersToEvaluate") {
            return this.$t('mission.alerts.workersToEvaluate', {total: alert.data.toEvaluate, plural: (alert.data.toEvaluate > 1 && 's') || ''});
        }
        if (alert.kind === "addWorkers") {
            return this.$t('mission.alerts.addWorkers');
        }
        if (alert.kind === "companyAsk") {
            return this.$t('mission.alerts.companyAsk');
        }
        if (alert.kind === "companyAskQuote") {
            return this.$t('mission.alerts.companyAskQuote');
        }
        if (alert.kind === "selectAgency") {
            return this.$t('mission.alerts.selectAgency');
        }

        if (alert.status === "aborted" || alert.kind === "workerAborted") {
            return this.$t('mission.alerts.status.aborted', {fullname: capitalize(alert.data.worker.firstname) + " " + capitalize(alert.data.worker.lastname)})
        }
        if (alert.status === "validated" || alert.kind === "workerValidated") {
            return this.$t('mission.alerts.status.validated', {clientname: alert.data.clientName, fullname: capitalize(alert.data.worker.firstname) + " " + capitalize(alert.data.worker.lastname)})
        }
        if (alert.status === "accepted" || alert.kind === "workerAccepted") {
            return this.$t('mission.alerts.status.accepted', {fullname: capitalize(alert.data.worker.firstname) + " " + capitalize(alert.data.worker.lastname)})
        }
        if (alert.status === "declined" || alert.kind === "workerDeclined") {
            return this.$t('mission.alerts.status.declined', {fullname: capitalize(alert.data.worker.firstname) + " " + capitalize(alert.data.worker.lastname)})
        }
        if (alert.status === "rejectedByCompany" || alert.kind === "workerRejectedByCompany") {
            return this.$t('mission.alerts.status.rejectedByCompany', {clientname: alert.data.clientName, fullname: capitalize(alert.data.worker.firstname) + " " + capitalize(alert.data.worker.lastname)})
        }
    }
}


import {Component, Vue, Prop} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import VModel from "@/mixins/VModel.vue";
import StarRating from 'vue-star-rating'

@Component({
    name: "Evaluation",
    components: {
        StarRating
    }
})
export default class Evaluation extends mixins(VModel) {
    @Prop(Boolean) readonly readonly!: boolean;
}

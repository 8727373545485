
    import {Component, Vue, Prop} from 'vue-property-decorator';
    import {mixins} from "vue-class-component";
    import ModalMixin from "@/components/modal/ModalMixin.vue";

    @Component({
        name: "CompanyEmailMissing"
    })
    export default class CompanyEmailMissing extends mixins(ModalMixin) {
        name = "CompanyEmailMissing";
    }


import { Component, Vue, Prop } from "vue-property-decorator";
import RoundRadio from "@/components/RoundRadio.vue";
import { mixins } from "vue-class-component";
import VModel from "@/mixins/VModel.vue";
import { DataSet, IMissionDB, LabelValue, MissionPeriod, MissionPositionFront, TimeSlot } from "@/types";
import { calculateDuration, capitalize, getDurationByUnit, getFormattedHour } from "@/helpers/commons";
import moment from "moment-timezone";
import InlineField from "@/components/InlineField.vue";
import Field from "./form/Field.vue";

@Component({
    name: "MissionPeriod",
    components: { RoundRadio },
})
export default class MissionPeriodLine extends mixins(VModel) {
    @Prop() errors!: any;
    @Prop() index!: number;
    @Prop() mission!: IMissionDB;
    @Prop(Boolean) alone!: boolean;

    get missionJobsOption() {
        return [
            {
                label: this.$t("page.orderCreation.steps.periods.allJobs"),
                value: "all",
            },
            ...(this.mission?.positions || [])
                .map((position: MissionPositionFront) => {
                    return {
                        label: capitalize(<string>position.job?.name),
                        value: position.jobId,
                    };
                })
                .filter((a: any) => a),
        ];
    }

    hasSlotsAvailable(period: any) {
        const nb = this.availableSlots(period).length - 1;

        if (this.missionJobsOption.length === 2) {
            return false;
        }

        return !!nb;
    }

    isSlotAvailable(period: MissionPeriod, jobId: string) {
        return !!this.availableSlots(period).find((option: LabelValue) => option.value === jobId);
    }

    filteredMissionJobs(period: MissionPeriod, currentKey: string) {
        return this.missionJobsOption.map((option: any) => {
            if (currentKey === option.value) {
                return option;
            }
            return {
                ...option,
                disabled: !this.isSlotAvailable(period, option.value),
            };
        });
    }

    availableSlots(period: MissionPeriod) {
        const founds: any = this.missionJobsOption.filter((ms: any) => {
            return Object.keys(period.slots).indexOf(ms.value) === -1;
        });

        return founds;
    }

    getDefaultSlot() {
        return [
            {
                from: getFormattedHour(930),
                to: getFormattedHour(1700),
            },
        ];
    }

    toggleWeek(period: MissionPeriod) {
        period.weekendIncluded = !period.weekendIncluded;
        this.$forceUpdate();
    }

    changePeriodSlotKey(period: MissionPeriod, oldKey: string, key: string) {
        period.slots[key] = period.slots[oldKey];
        delete period.slots[oldKey];
        this.$forceUpdate();
    }

    removeTime(slots: TimeSlot[], index: number) {
        slots.splice(index, 1);
        this.$forceUpdate();
    }

    addHours(slots: TimeSlot[]) {
        slots.push(this.getDefaultSlot()[0]);
        this.$forceUpdate();
    }

    addSlot(period: MissionPeriod) {
        const slots = this.availableSlots(period);

        if (slots.length) {
            period.slots[slots[0].value] = this.getDefaultSlot();
            this.$forceUpdate();
        }
    }
    formattedSlots(slots: DataSet<TimeSlot[]>) {
        return Object.keys(slots).map((key: string) => {
            return {
                slots: slots[key],
                key,
            };
        });
    }

    removeSlot(period: MissionPeriod, key: string) {
        delete period.slots[key];
        this.$forceUpdate();
    }

    changeDate(index: any, context: string, value: number) {
        if (this.innerValue) {
            this.$set(this.innerValue, context, value);
            const s = moment(this.innerValue.start).startOf("day").valueOf();
            const e = moment(this.innerValue.end).startOf("day").valueOf();

            const totalDuration = calculateDuration(s, e, "day");
            if (!this.errors[index]) {
                this.$set(this.errors, index, {});
            }

            if (totalDuration > 548) {
                if (s < e) {
                    this.$set(this.errors[index], "end", { id: "Invalid.dateLongerInvalid" });
                } else if (s > e) {
                    this.$set(this.errors[index], "end", { id: "Invalid.EndDateInferior" });
                }
            } else {
                if (s > e) {
                    this.$set(this.errors[index], "end", { id: "Invalid.EndDateInferior" });
                } else {
                    delete this.errors[index].end;
                }
            }
            if (!this.includesWeekend(this.innerValue)) {
                this.$set(this.innerValue, "weekendIncluded", false);
            } else if (this.isInWeekend(this.innerValue)) {
                this.$set(this.innerValue, "weekendIncluded", true);
            }
        }
        this.$forceUpdate();
    }

    includesWeekend(period: MissionPeriod) {
        const s = moment(period.start);
        const e = moment(period.end);
        let i = 0,
            nbDays = Math.floor(e.diff(s, "days", true));

        while (i <= nbDays) {
            const d = s.clone().add(i, "days").weekday();

            if (d === 5 || d === 6) {
                return true;
            }
            ++i;
        }

        if (!nbDays) {
            if (s.weekday() === 5 || s.weekday() === 6) {
                return true;
            }
        }

        return false;
    }

    isInWeekend(period: MissionPeriod) {
        const s = moment(period.start);
        const e = moment(period.end);
        let nbDays = Math.floor(e.diff(s, "days", true));

        if (nbDays > 2) {
            return false;
        }

        return (s.weekday() === 5 || s.weekday() === 6) && (e.weekday() === 5 || e.weekday() === 6);
    }

    getError(key: string, slotName: string) {
        if (this.errors.timetable && this.errors.timetable.data.jobId === key && this.errors.timetable.data.missingKey === slotName) {
            return this.errors.timetable;
        }

        return null;
    }
}


import {Component, Vue, Prop} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import ModalMixin from "@/components/modal/ModalMixin.vue";
import Evaluation from "@/components/Evaluation.vue";
import RoundRadio from "@/components/RoundRadio.vue";
import {capitalize} from "@/helpers/commons";

@Component({
    name: "EvaluationModal",
    components: {RoundRadio, Evaluation}
})
export default class EvaluationModal extends mixins(ModalMixin) {
    @Prop() workers!: any;
    @Prop() evaluations!: any;
    name = "EvaluationModal";
    notes: any = [];

    initNotes() {
        let list:any = this.evaluations || this.workers;
        list.forEach((worker:any) => {
            this.notes.push({
                fullName: `${capitalize(worker.firstname)} ${capitalize(worker.lastname)}`,
                _id: worker.workerId || worker._id,
                note: 0,
                comment: "",
                blacklisted: false
            })
        });
    }

    beforeMount() {
        this.initNotes();
    }
}

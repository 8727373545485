
import {Component, Vue, Prop} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import ModalMixin from "@/components/modal/ModalMixin.vue";
import {Getter} from "vuex-class";
import {LabelValue} from "@/types";
import ActionButton from "@/components/ActionButton.vue";

@Component({
    name: "CancelMission",
    components: {ActionButton}
})
export default class CancelMission extends mixins(ModalMixin) {
    name = "CancelMission";

    reason = "clientNeedNoLongerExists";
    reasonText = "";

    @Getter('cancelReasonsOptions') cancelReasonsOptions!:LabelValue[];
    @Getter('isCompany') isCompany!: boolean;

    beforeMount() {
        if (this.isCompany) {
            this.reason = "myNeedNoLongerExists";
        }
    }
}


    import {Component, Vue, Prop} from 'vue-property-decorator';
    import {mixins} from "vue-class-component";
    import ModalMixin from "@/components/modal/ModalMixin.vue";
    import {IMissionDB, LabelValue} from "@/types";
    import moment from "moment-timezone";
    import {capitalize, getFormattedHour} from "@/helpers/commons";
    import MissionPeriodLine from "@/components/MissionPeriodLine.vue";
    import RoundRadios from "@/components/RoundRadios.vue";
    import {WorkerMissionStatus} from "@/data";
    import clonedeep from "lodash.clonedeep";
    import { Getter } from 'vuex-class';

    @Component({
        name: "ExtendMission",
        components: {RoundRadios, MissionPeriodLine}
    })
    export default class ExtendMission extends mixins(ModalMixin) {
        name = "ExtendMission";

        @Prop() errors!: any;
        @Prop() mission!: IMissionDB;
        @Getter('jobOptions') jobOptions!: LabelValue[];

        period:any = {
            start: moment().add(1, 'day').startOf('day').valueOf(),
            end: moment().add(1, 'day').add(1, 'week').startOf('day').valueOf(),
            slots: {
                all: [{
                    from: getFormattedHour(930),
                    to: getFormattedHour(1700)
                }]
            }
        };
        selectedWorkers:any = [];
        description:string = '';
        copy = false;
        positions: any = [];

        get workers() {
            return this.mission?.workers?.filter((w:any) => w.status === WorkerMissionStatus.confirmed) || [];
        }

        nameJob(position: any) {
            return this.getJob(position.jobId)?.label
        }

        getJob(id: string) {
            return this.jobOptions.find((i: LabelValue) => i.value === id);
        }

        copyAll() {
            this.$emit('submit', {copy: true});
        }

        displayWorker(worker:any) {
            return `${capitalize(worker.firstname)} ${capitalize(worker.lastname)}`;
        }

        onSelect(workers:any) {
            this.selectedWorkers = workers;
        }

        beforeMount() {
            if (this.mission?.periods) {
                let firstPeriod = clonedeep(this.mission.periods[0]);

                if (firstPeriod) {
                    if (moment(firstPeriod.end).weekday() === 4 && !firstPeriod.weekendIncluded) {
                        this.period.start = moment(firstPeriod.end).add(3, 'days').startOf('day').valueOf();
                        this.period.end = moment(this.period.start).add(4, 'days').endOf('day').valueOf();
                    } else {
                        this.period.start = moment(firstPeriod.end).add(1, 'days').startOf('day').valueOf();
                        this.period.end = moment(this.period.start).add(Math.abs(moment(this.period.start).weekday() - 5), 'days').endOf('day').valueOf();
                    }
                    if (firstPeriod.slots?.all) {
                        this.period.slots.all = firstPeriod.slots.all.map((s:any) => {
                            s.from = getFormattedHour(s.from);
                            s.to = getFormattedHour(s.to);
                            return s;
                        });
                    }
                }
            }
            this.workers?.forEach((w:any) => {
                w._id = w.workerId;
                this.selectedWorkers.push(w);
            })
            this.description = this.mission.description || '';
            this.positions = this.mission.positions;
        }
    }
